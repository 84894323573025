import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full foot fixed bottom-0 bg-black text-white flex justify-center items-center">
      <button className="slide-button text-3xl font-semibold p-2 w-full">
        Reach Out
      </button>
    </footer>
  );
};

export default Footer;
