
import React,{ useEffect } from 'react';
import {useLocation} from 'react-router-dom'
import video from '../Images/bg.webm';
import br1 from '../Images/Visual Media/Assorted Visual Media-1.webp';
import br2 from '../Images/Visual Media/Assorted Visual Media-2.webp';
import br3 from '../Images/Visual Media/Assorted Visual Media-3.webp';
import { IoStar } from "react-icons/io5";
import br4 from '../Images/Visual Media/Searchlight-1.webp';
import br5 from '../Images/Visual Media/Searchlight-2.webp';
import br6 from '../Images/Visual Media/Searchlight-3.webp';
import br7 from '../Images/Visual Media/Visual Media-Mellowtone-1.webp';
import br8 from '../Images/Visual Media/Visual Media-Mellowtone-2.webp';
import br9 from '../Images/Visual Media/Visual Media-Mellowtone-3.webp';

const Visual = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const section = document.querySelector(location.hash);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <>
      <div className='nnnp'  id='Assorted'>
      <div id='branding' className='mx-64 mt-24'>
        <div className='row flex flex-row ' >
          <div className='col-lg-12 col-md-12 col-12 w-full h-screen flex justify-center relative'>
            {/* Image */}
            <img src={br1} className='h-screen w-full rounded-3xl imgs' alt='Branding Nesod' />
            
            {/* Text positioned at the top-left of the image */}
            <div className='absolute top-0 left-1 bg-black p-3 rounded-xl'>
              <h1 className='text-5xl font-bold text-white tk'>Assorted</h1>
            </div>
            <div className='absolute bottom-0 right-2 bg-black p-3 w-96 text-wrap rounded-xl'>
              <h1 className='text-3xl font-thin text-white'>BRIEF</h1>
              <p className='text-1xl font-thin'>Create a distinctive logo and branding for a Norwegian honey producer, Nesoddbiene, founded by beekeepers Georges and Ronni. With a passion for beekeeping since 2018, they sell honey and maintain a small local lot. Committed to quality, we wanted to give them something modern and memorable.</p>
            
            </div>
          </div>

          <div className='flex flex-row mt-5'>
            <div className='col-6 col-lg-6 col-md-6'>
                <img src={br2} className='rounded-3xl' />
                <h1 className='text-3xl font-thin text-white mt-10'>REVIEW</h1>
              <p className='text-1xl font-thin'>The team brilliantly captured Nesoddbiene's essence, crafting a logo and branding that radiate modernity and memorability. Reflecting our commitment to quality honey, the design resonates with our passion for beekeeping. The distinctive identity perfectly mirrors our story, making Nesoddbiene stand out in the hive of honey producers. Exceptional work!</p>
              <div className='mt-4'>
              <div className=" relative h-56 w-full overflow-hidden">
      
      {/* Stars with video inside */}
      <div className="absolute top-5 left-1 flex space-x-2">
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
      </div>
    </div>
              
              </div>
            </div>
            <div className='col-6 col-lg-6 col-md-6 ml-4 px-10'>
            <h1 className='text-3xl font-thin text-white'>PROCESS</h1>
              <p className='text-1xl font-thin'>We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.

<br/>We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.</p>
            <img src={br3} className='rounded-3xl mt-14'/>
            </div>
          </div>
        </div>
        </div>
     {/* section2 */}
     <div className='nnnp' id='Searchlight'>
     <div id='branding' className='mx-64 mt-24'>
        <div className='row flex flex-row ' >
          <div className='col-lg-12 col-md-12 col-12 w-full h-screen flex justify-center relative'>
            {/* Image */}
            <img src={br4} className='h-screen w-full rounded-3xl imgs' alt='Branding Nesod' />
            
            {/* Text positioned at the top-left of the image */}
            <div className='absolute top-0 left-1 bg-black p-3 rounded-xl'>
              <h1 className='text-5xl font-bold text-white'>Searchlight</h1>
            </div>
            <div className='absolute bottom-0 right-2 bg-black p-3 w-96 text-wrap rounded-xl'>
              <h1 className='text-3xl font-thin text-white'>BRIEF</h1>
              <p className='text-1xl font-thin'>Create a distinctive logo and branding for a Norwegian honey producer, Nesoddbiene, founded by beekeepers Georges and Ronni. With a passion for beekeeping since 2018, they sell honey and maintain a small local lot. Committed to quality, we wanted to give them something modern and memorable.</p>
            
            </div>
          </div>

          <div className='flex flex-row mt-5'>
         
            <div className='col-6 col-lg-6 col-md-6 '>
            <h1 className='text-3xl font-thin text-white'>PROCESS</h1>
              <p className='text-1xl font-thin'>We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.

<br/>We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.</p>
            <img src={br5} className='rounded-3xl mt-14'/>
            </div>
            <div className='col-6 col-lg-6 ml-4 col-md-6 px-10'>
                <img src={br6} className='rounded-3xl' />
                <h1 className='text-3xl font-thin text-white mt-10'>REVIEW</h1>
              <p className='text-1xl font-thin'>The team brilliantly captured Nesoddbiene's essence, crafting a logo and branding that radiate modernity and memorability. Reflecting our commitment to quality honey, the design resonates with our passion for beekeeping. The distinctive identity perfectly mirrors our story, making Nesoddbiene stand out in the hive of honey producers. Exceptional work!</p>
              <div className='mt-4'>
              <div className=" relative h-72 overflow-hidden">
      
      {/* Stars with video inside */}
      <div className="absolute top-5 left-1 flex space-x-2">
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
      </div>
    </div>
              
              </div>
            </div>
          </div>
        </div>
        </div></div>
         {/* section3 */}
         <div className='nnnp'  id='Mellowtone'>
         <div id='branding' className='mx-64 mt-24'>
         <div className='row flex flex-row '>
          <div className='col-lg-12 col-md-12 col-12 w-full h-screen flex justify-center relative'>
            {/* Image */}
            <img src={br7} className='h-screen w-full rounded-3xl imgs' alt='Branding Nesod' />
            
            {/* Text positioned at the top-left of the image */}
            <div className='absolute top-0 left-1 bg-black p-3 rounded-xl'>
              <h1 className='text-5xl font-bold text-white'>Mellowtone</h1>
            </div>
            <div className='absolute bottom-0 right-2 bg-black p-3 w-96 text-wrap rounded-xl'>
              <h1 className='text-3xl font-thin text-white'>BRIEF</h1>
              <p className='text-1xl font-thin'>Create a distinctive logo and branding for a Norwegian honey producer, Nesoddbiene, founded by beekeepers Georges and Ronni. With a passion for beekeeping since 2018, they sell honey and maintain a small local lot. Committed to quality, we wanted to give them something modern and memorable.</p>
            
            </div>
          </div>

          <div className='flex flex-row mt-5'>
         
            <div className='col-6 col-lg-6 col-md-6 '>
            <h1 className='text-3xl font-thin text-white'>PROCESS</h1>
              <p className='text-1xl font-thin'>We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.

<br/>We began by immersing ourselves in their world, understanding their beekeeping journey and values. Collaborating closely, we translated their passion into a unique logo and labels. Iterative discussions refined the design, ensuring it resonated with their commitment to quality and showcased the essence of their beekeeping endeavours.</p>
            <img src={br8} className='rounded-3xl mt-14'/>
            </div>
            <div className='col-6 col-lg-6 ml-4 col-md-6 px-10'>
                <img src={br9} className='rounded-3xl' />
                <h1 className='text-3xl font-thin text-white mt-10'>REVIEW</h1>
              <p className='text-1xl font-thin'>The team brilliantly captured Nesoddbiene's essence, crafting a logo and branding that radiate modernity and memorability. Reflecting our commitment to quality honey, the design resonates with our passion for beekeeping. The distinctive identity perfectly mirrors our story, making Nesoddbiene stand out in the hive of honey producers. Exceptional work!</p>
              <div className='mt-4'>
              <div className=" relative h-72  overflow-hidden">
      
      {/* Stars with video inside */}
      <div className="absolute top-5 left-1 flex space-x-2">
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="relative w-12 h-12">
          <IoStar size={60} className="absolute inset-0 text-transparent" />
          <div className="absolute inset-0 clip-star">
            <video className="h-full w-full object-cover" src={video} autoPlay muted loop playsInline />
          </div>
        </div>
      </div>
    </div>
              
              </div>
            </div>
          </div>
        </div></div>

 
      </div>
    </div> 
    </>
  )
}

export default Visual
