import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import vid from '../Icons/BRANDING_anim.gif';
import img from '../Icons/BRANDING.png';
import vid1 from '../Icons/PACKAGING_anim.gif';
import img1 from '../Icons/PACKAGING.png';
import vid2 from '../Icons/PRESENTATIONS_anim.gif';
import img2 from '../Icons/PRESENTATIONS.png';
import vid3 from '../Icons/ILLUSTRATION_anim.gif';
import img3 from '../Icons/Illustration.png';
import vid4 from '../Icons/WEB_anim.gif';
import img4 from '../Icons/WEB (1).png';
import vid5 from '../Icons/3D_anim.gif';
import img5 from '../Icons/3D (1).png';
import vid6 from '../Icons/SOCIAL-MEDIA_anim.gif';
import img6 from '../Icons/SOCIAL-MEDIA.png';
import vis from '../Icons/Visual-Media.png'
import vis1 from '../Icons/VISUAL-MEDIA_anim.gif'

const Navbar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false); 
  const [hoveredItem, setHoveredItem] = useState(null); 
  const [iconOpacity, setIconOpacity] = useState(1);

  const handleClick = (key) => {
    setActiveItem(key);
  };

  const resetIconsAndActive = () => {
    setActiveItem(null); 
    setIconOpacity(0.6); 
  };

  const renderNavItem = (key, label, img, vid, link) => (
    <li
      key={key}
      className={`cursor-pointer flex flex-col items-center transition-all duration-300 ${activeItem === key ? 'brightness-125' : 'brightness-75'}`}
      onClick={() => handleClick(key)}
      onMouseEnter={() => setHoveredItem(key)} 
      onMouseLeave={() => setHoveredItem(null)} 
    >
      <Link to={link} className="flex flex-col items-center">
        <img
          src={hoveredItem === key || activeItem === key ? vid : img}
          alt={`${label} Icon`}
          className="gif-icon w-16 h-16 mb-2" 
          style={{ opacity: iconOpacity }} 
        />
        <span className="text-center">{label}</span>
      </Link>
    </li>
  );

  return (
    <div className="bg-black w-full">
      <nav className="bg-neutral-950  p-2 flex justify-between items-center">
      <Link to={'/'} >
        <div className='pb-2 lg:px-12' onClick={resetIconsAndActive}>
         <h1 className='text-5xl  bacl  mkmkkm'> laconic.  </h1>
            <p className='text-sm fonsi'>adj. Expressing much in few words</p>
        </div>
        </Link>
        {/* Hamburger Icon */}
        <div className="md:hidden flex items-center">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FiX size={30} /> : <FiMenu size={30} />}
          </button>
        </div>

        {/* Full menu for larger screens */}
        <ul className="hidden md:flex space-x-8 lg:space-x-9 items-center">
          {renderNavItem('branding', 'Branding',vid ,vid,'/branding')}
          {renderNavItem('packaging', 'Packaging', vid1, vid1, '/packaging')}
          {renderNavItem('presentations', 'Presentations',vid2,  vid2, '/presentation')}
          {renderNavItem('illustration', 'Illustration', vid3,vid3, '/illustration')}
          {renderNavItem('visual', 'Visual', vis1, vis1, '/visual')}
          {renderNavItem('web', 'Web', vid4,vid4, '/web')}
          {renderNavItem('3d', '3D',  vid5,vid5, '/3d')}
          {renderNavItem('social', 'Social Media', vid6,vid6, '/social')}

          <li>
          <div className="flex flex-col items-center justify-center h-32 w-32 rounded-full bg-gradient-to-b from-zinc-800/80 to-zinc-800/60 overflow-hidden">
  <button className="w-full text-white px-2 py-3 transition-colors duration-300 sajcndhbc">
    ABOUT US
  </button>
  <button className="w-full text-white px-2 py-3 border-t transition-colors duration-300 sajcndhbc">
    FAQ
  </button>
</div>

          </li>
        </ul>

        {/* Hamburger Menu for small screens */}
        {isOpen && (
          <ul className="absolute top-16 right-0 w-40 bg-neutral-950 text-white flex flex-col space-y-4 items-center py-4 z-20 md:hidden">
            {renderNavItem('branding', 'Branding', vid ,vid, '/branding')}
            {renderNavItem('packaging', 'Packaging', vid1, vid1, '/packaging')}
            {renderNavItem('presentations', 'Presentations',  vid2, vid2, '/presentations')}
            {renderNavItem('illustration', 'Illustration', vid3, vid3, '/illustration')}
            {renderNavItem('visual', 'Visual', vis1, vis1, '/visual')}
            {renderNavItem('web', 'Web', vid4, vid4, '/web')}
            {renderNavItem('3d', '3D', vid5, vid5, '/3d')}
            {renderNavItem('social', 'Social Media', vid6, vid6, '/social')}

            <li>
              <div className="flex flex-col border-2 items-center justify-center h-26 w-32 rounded-full bg-gradient-to-b from-zinc-800/80 to-zinc-800/60 text-white overflow-hidden">
              {/*   <button className="w-full text-white px-2 py-2 border-b-2 transition-colors duration-300 hover:bg-white hover:text-black">
                  REVIEWS
                </button> */}
                <button className="w-full text-white px-2 py-2 transition-colors duration-300 hover:bg-white hover:text-black">
                  ABOUT US
                </button>
                <button className="w-full text-white px-2 py-2 border-t-2 transition-colors duration-300 hover:bg-white hover:text-black">
                  FAQ
                </button>
              </div>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
