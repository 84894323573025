import React, { useRef, useEffect } from 'react';
import img from '../Images/Branding/Branding-Nesod-2.webp';
import img1 from '../Images/Branding/Airquip-1.webp';
import img2 from '../Images/Branding/Narrative Talent-1.webp';
import pack from '../Images/Packaging/HempMe-2.webp'
import pack1 from '../Images/Packaging/Packaging-La Isla-2.webp'
import pack2 from '../Images/Packaging/Packaging-Ultima-2.webp'
import pre from '../Images/Presentations/Hawkeye-2.webp'
import pre1 from '../Images/Presentations/Presentation-Dogma-2.webp'
import pre2 from '../Images/Presentations/Presentation-Forge-2.webp'
import vis from '../Images/Visual Media/Assorted Visual Media-2.webp'
import vis1 from '../Images/Visual Media/Searchlight-2.webp'
import vis2 from '../Images/Visual Media/Visual Media-Mellowtone-2.webp'
import web from '../Images/Web/Vayo-2.webp'
import web1 from '../Images/Web/Web-Bailuna-2.webp'
import web2 from '../Images/Web/Web-Totus-2.webp'
import thr from '../Images/3D/3D-Homestead2100-2.webp'
import thr1 from '../Images/3D/3D-Mooch-2.webp'
import thr2 from '../Images/3D/Vera-2.webp'
import { Link } from 'react-router-dom'
import ill from '../Images/Illustrations/Assorted Illustrations-1.webp'
import ill1 from '../Images/Illustrations/Illustration-Animal Gang-1.webp'
import ill2 from '../Images/Illustrations/Soul Masters-1.webp'

import './MultiImage.css'

const MultiImage = () => {
  const scrollDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // You can adjust the factor to control the scroll speed
      scrollDivRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup event listener
    };
  }, []);
  
  return (
    <div className='container'>
      {/* Branding */}
      <div className='row'>
        <div className='col-6 pt-6 pl-10 '>
          <h1 className="tadn text-5xl bacl">BRANDING</h1>
          <p className="mt-3">
            <span className="asn block">The front of your business</span>
            <span className="fons ">from vision to Vector</span>
          </p>
          <p className="mt-4 pb-5 pr-10 fons font-thin">
            We create distinctive brand identities that reflect your business’s core values and connect with your target audience. Our branding services include logo design, typography, color schemes, and comprehensive style guides to ensure consistency across all platforms. Whether building a new brand or refreshing an existing one, we craft identities that resonate, foster trust, and make your brand memorable. Our strategic approach focuses on building recognition and loyalty through cohesive visual storytelling, ensuring your brand stands out in a competitive market and leaves a lasting impression on customers.
          </p>
        </div>
        
        <div className='col-6  overflow-scroll no-scrollbar snap-normal heij'>
          <div className="image-scroller" ref={scrollDivRef}>
            <div className="image-container" >

              {/* Image 1 */}
              <div className="image-item relative z-10">
                <img src={img} alt="Image 1" className="w-full imgs h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                  <Link to="/branding#Nesod">
                    <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">

                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                        E X P L O R E
                      </div>
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                        E X P L O R E
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Image 2 */}
              <div className="image-item relative z-20 pt-2">
                <img src={img1} alt="Image 2" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                  <Link to="/branding#Airquip">
                    <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                        E X P L O R E
                      </div>
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                        E X P L O R E
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Image 3 */}
              <div className="image-item relative z-30 pt-2">
                <img src={img2} alt="Image 3" className="w-full h-auto imgs"  />

                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                  <Link to="/branding#Narrative">
                    <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                        E X P L O R E
                      </div>
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                        E X P L O R E
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Packaging */}
      <div className='row mt-32'>

        <div className='col-6 overflow-scroll no-scrollbar snap-normal heij'>
          <div className="image-scroller" ref={scrollDivRef}>
            <div className="image-container">

              {/* Image 1 with Explore Button */}
              <div className="image-item relative z-10">
                <img src={pack} alt="Image 1" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <Link to="/packaging#HempMe">
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                        E X P L O R E
                      </div>
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                        E X P L O R E
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Image 2 with Explore Button */}
              <div className="image-item relative z-20 pt-2">
                <img src={pack1} alt="Image 2" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                  <Link to="/packaging#LAISLA">
                    <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                        E X P L O R E
                      </div>
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                        E X P L O R E
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Image 3 with Explore Button */}
              <div className="image-item relative z-30 pt-2">
                <img src={pack2} alt="Image 3" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                  <Link to="/packaging#ULTIMA">
                    <div className="absolute and w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                        E X P L O R E
                      </div>
                      <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                        E X P L O R E
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className='col-6 pt-6  pl-10 '>
          <h1 className="text-5xl bacl tadn  z-10">PACKAGING</h1>
          <p className="mt-3">
            <span className="asn block">Elevate your product</span>
            <span className="fons">With expertly crafted designs</span>
          </p>
          <p className="mt-4 fons">
            We design eye-catching, functional packaging that enhances your product’s appeal while maintaining practical considerations. Our packaging solutions are tailored to your brand’s identity, ensuring that your product stands out on the shelf and attracts customers. Whether for retail, food, or luxury products, we balance aesthetic beauty with usability, making sure your packaging is not only visually compelling but also sustainable and cost-effective. We focus on delivering designs that communicate your product’s value, making a memorable first impression and enhancing the overall customer experience from the moment they see your product.
          </p>
        </div>



      </div>

      {/* Presentation */}
      <div className='row mt-32'>
        <div className='col-6 pt-6 pl-10 '>
          <h1 className="text-5xl tadn bacl">PRESENTATIONS</h1>
          <p className="mt-3">
            <span className="asn block">Transform your ideas </span>
            <span className="fons">into visually stunning presentations</span>
          </p>
          <p className="mt-4 fons">
            We design professional, visually impactful presentations that make your message clear and compelling. Whether for corporate meetings, sales pitches, or conferences, our custom-designed presentations are tailored to your brand’s style and goals. We focus on visual storytelling, combining engaging graphics, clean layouts, and clear messaging to create presentations that captivate and inform your audience. Our services ensure your presentations are not only informative but also visually striking, helping you communicate effectively, stand out, and leave a lasting impression on stakeholders or clients.
          </p>
        </div>


        <div className='col-6 overflow-scroll no-scrollbar snap-normal heij'>
          <div className="image-scroller">
            <div className="image-container">
            
              {/* Image 1 with Explore Button */}
              <div className="image-item relative z-10">
                <img src={pre} alt="Image 1" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/presentation#Hawkeye">
                  <div className="absolute  w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
                
              </div>
              
              {/* Image 2 with Explore Button */}
              <div className="image-item relative z-20 pt-2">
                <img src={pre1} alt="Image 2" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/presentation#Dogma">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 3 with Explore Button */}
              <div className="image-item relative z-30 pt-2">
                <img src={pre2} alt="Image 3" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/presentation#Forge">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
  {/* Illustration */}
  <div className='row mt-32'>

<div className='col-6 overflow-scroll no-scrollbar snap-normal heij'>
  <div className="image-scroller">
    <div className="image-container">

      {/* Image 1 with Explore Button */}
      <div className="image-item relative z-10">
        <img src={ill} alt="Image 1" className="w-full h-auto imgs" />
        <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
        <Link to="/illustration#SoulMasters">
          <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
            <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
              E X P L O R E
            </div>
            <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
              E X P L O R E
            </div>
          </div>
          </Link>
        </div>
      </div>

      {/* Image 2 with Explore Button */}
      <div className="image-item relative z-20 pt-2">
        <img src={ill1} alt="Image 2" className="w-full h-auto imgs" />
        <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
        <Link to="/illustration#AnimalGang">
          <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
            <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
              E X P L O R E
            </div>
            <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
              E X P L O R E
            </div>
          </div>
          </Link>
        </div>
      </div>

      {/* Image 3 with Explore Button */}
      <div className="image-item relative z-30 pt-2">
        <img src={ill2} alt="Image 3" className="w-full h-auto imgs" />
        <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
        <Link to="/illustration#Assorted">
          <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
            <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
              E X P L O R E
            </div>
            <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
              E X P L O R E
            </div>
          </div>
          </Link>
        </div>
      </div>

    </div>
  </div>
</div>


<div className='col-6 pt-6  pl-10 '>
  <h1 className="text-5xl tadn bacl">ILLUSTRATION</h1>
  <p className="mt-3">
    <span className="asn block">Ignite your content</span>
    <span className="fons">with engaging and captivating visuals</span>
  </p>
  <p className="mt-4 fons">
    Our visual media services create engaging and high-quality content, from videos to motion graphics, that communicates your brand’s message effectively. We handle the entire production process, from concept development to post-production, ensuring your content aligns with your brand and goals. Whether it’s promotional videos, product demos, or brand storytelling, our visual media services help you engage your audience across digital platforms. We focus on delivering compelling narratives through visual storytelling, boosting your brand’s visibility, and helping you build deeper connections with your target audience.              </p>

</div>



</div>

      {/* VISUAL MEDIA */}
      <div className='row mt-32'>

      


        <div className='col-6 pt-6 pl-10 '>
          <h1 className="text-5xl tadn bacl">VISUAL MEDIA</h1>
          <p className="mt-3">
            <span className="asn block">Ignite your content</span>
            <span className="fons">with engaging and captivating visuals</span>
          </p>
          <p className="mt-4 fons">
            Our visual media services create engaging and high-quality content, from videos to motion graphics, that communicates your brand’s message effectively. We handle the entire production process, from concept development to post-production, ensuring your content aligns with your brand and goals. Whether it’s promotional videos, product demos, or brand storytelling, our visual media services help you engage your audience across digital platforms. We focus on delivering compelling narratives through visual storytelling, boosting your brand’s visibility, and helping you build deeper connections with your target audience.              </p>

        </div>

        <div className='col-6 overflow-scroll no-scrollbar snap-normal heij'>
          <div className="image-scroller">
            <div className="image-container">

              {/* Image 1 with Explore Button */}
              <div className="image-item relative z-10">
                <img src={vis} alt="Image 1" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/visual#Assorted">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 2 with Explore Button */}
              <div className="image-item relative z-20 pt-2">
                <img src={vis1} alt="Image 2" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/visual#Searchlight">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 3 with Explore Button */}
              <div className="image-item relative z-30 pt-2">
                <img src={vis2} alt="Image 3" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/visual#Mellowtone">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      {/* WEB */}
      <div className='row mt-32'>
        


        <div className='col-6 overflow-scroll no-scrollbar snap-normal heij'>
          <div className="image-scroller">
            <div className="image-container">

              {/* Image 1 with Explore Button */}
              <div className="image-item relative z-10">
                <img src={web} alt="Image 1" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/web#Vayo">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 2 with Explore Button */}
              <div className="image-item relative z-20 pt-2">
                <img src={web1} alt="Image 2" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/web#Bailuna">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolutem text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 3 with Explore Button */}
              <div className="image-item relative z-30 pt-2">
                <img src={web2} alt="Image 3" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/web#Totus">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='col-6 pt-6  pl-10 '>
          <h1 className="text-5xl tadn bacl">WEB</h1>
          <p className="mt-3">
            <span className="asn block">Unleash the power of your brand</span>
            <span className="fons">with strategic UI & UX</span>
          </p>
          <p className="mt-4 fons">
            We design and develop custom websites that are visually stunning, responsive, and user-friendly. Our web solutions are tailored to your business needs, whether it’s an e-commerce platform, corporate site, or blog. We focus on user experience (UX), intuitive navigation, and seamless functionality to ensure maximum engagement and conversions. Our websites are optimized for SEO and mobile compatibility, delivering a consistent and attractive user experience across all devices. From concept to launch, we handle everything, including ongoing support and updates, ensuring your site remains secure, up-to-date, and aligned with your business goals.              </p>

        </div>

      </div>

      {/* 3D */}
      <div className='row mt-32 mb-10'>

      <div className='col-6 pt-6 pl-10 '>
          <h1 className="text-5xl font-bold bacl">3D</h1>
          <p className="mt-3">
            <span className="asn block">Another dimension of creativity</span>
            <span className="fons">with immersive 3D designs</span>
          </p>
          <p className="mt-4 fons">
            Our 3D design services bring concepts, products, and environments to life with exceptional realism. We specialize in 3D modeling, rendering, and animation, delivering detailed visuals for industries like real estate, product design, marketing, and entertainment. Whether you need product prototypes, architectural models, or engaging animations, our expertise ensures precision and creativity. We transform ideas into dynamic, interactive experiences that captivate and communicate effectively. Our 3D designs not only enhance visual appeal but also provide immersive experiences, helping your brand stand out and making complex ideas easier to grasp.              </p>

        </div>

        <div className='col-6 overflow-scroll no-scrollbar snap-normal heij'>
          <div className="image-scroller">
            <div className="image-container">

              {/* Image 1 with Explore Button */}
              <div className="image-item relative z-10">
                <img src={thr} alt="Image 1" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/3d#Homestead2100">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 2 with Explore Button */}
              <div className="image-item relative z-20 pt-2">
                <img src={thr1} alt="Image 2" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/3d#Mooch">
                  <div className="absolute  w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute text-xs and w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

              {/* Image 3 with Explore Button */}
              <div className="image-item relative z-30 pt-2">
                <img src={thr2} alt="Image 3" className="w-full h-auto imgs" />
                <div className="absolute bottom-6 right-4 w-24 h-24 perspective">
                <Link to="/3d#Vera">
                  <div className="absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d hover:rotate-y-180">
                    <div className="absolute and text-xs w-full h-full buu text-white rounded-full flex items-center justify-center font-mono backface-hidden">
                      E X P L O R E
                    </div>
                    <div className="absolute and text-xs  w-full h-full buu text-white rounded-full flex items-center justify-center font-mono rotate-y-180">
                      E X P L O R E
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>


       



      </div>

     
    </div>
  )
}

export default MultiImage

