import React, { useEffect, useRef, useState } from 'react';
import video from '../Images/bg.webm';

import img2 from '../Images/Branding/Branding-Nesod-2.webp';
import img3 from '../Images/Packaging/Packaging-Ultima-2.webp';
import img4 from '../Images/Presentations/Presentation-Dogma-2.webp';
import img5 from '../Images/Visual Media/Searchlight-2.webp';
import img6 from '../Images/Web/Vayo-2.webp';
import img7 from '../Images/Illustrations/Soul Masters-2.webp';
import img8 from '../Images/3D/3D-Homestead2100-2.webp';

import MultiImage from '../Components/MultiImage';
import tri from '../Icons/triangle (2).png'
import tri2 from '../Icons/triangle3.png'
import tri1 from '../Icons/triangle1.png'

const Landing = () => {
  // Observer state to detect when section is visible
  const [isVisible, setIsVisible] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768); // To detect screen size
  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle window resize events
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    // Set up intersection observer for large screens only
    if (isLargeScreen && divRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        {
          threshold: 0.01, // Trigger when 1% of the div is in view
        }
      );
      observer.observe(divRef.current);

      return () => {
        if (divRef.current) {
          observer.unobserve(divRef.current);
        }
        window.removeEventListener('resize', handleResize);
      };
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [isLargeScreen]);

  const BrandingSection = () => (
    <div className=' flex justify-start'>
      {/* <div id="carouselVideoExample" className="carousel slide carousel-fade" data-bs-ride="carousel">
  
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselVideoExample" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselVideoExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselVideoExample" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>

  <div className="carousel-inner">
   
   
    <div className="carousel-item active">
      <video className="bg-video object-cover w-full h-full" src={video} autoPlay muted loop></video>
      <div className="carousel-caption absolute top-1 left-0 d-block">
        <h1 className=' text-black'><span className='font-bold'>laconic.</span></h1>
        <p className='fs-3'>adj. Expressing much in few words</p>
      </div>
    </div>

  
    <div className="carousel-item">
      <video className="w-100" src={video} autoPlay muted loop></video>
      <div className="carousel-caption d-block">
        <div className="row justify-content-center">
          <div className="col-md-5 text-5xl text-end font-bold text-uppercase mb-4">
            <p className='text-black'>We <br /> are <br /> Artists</p>
          </div>
          <div className='col-1 bg-black d-none d-md-block'></div>
          <div className="col-md-7 text-start font-medium text-uppercase">
            <h1 className='text-black fs-3'>Our values: Artistic Exploration, Clear Communication with Creative Input & Feedback</h1>
          </div>
        </div>
      </div>
    </div>

    
    <div className="carousel-item">
      <video className="w-100" src={video} autoPlay muted loop></video>
      <div className="carousel-caption d-block">
        <div className="row justify-content-center">
          <div className="col-md-5 text-end font-bold text-uppercase mb-4">
            <p className='text-black fs-1'>Explore <br /> Design <br /> Create</p>
          </div>
          <div className="col-1 d-flex flex-column align-items-center">
            <img src={tri2} className='mb-2' style={{ height: '30px' }} />
            <img src={tri} className='mb-2' style={{ height: '30px' }} />
            <img src={tri1} className='' style={{ height: '40px' }} />
          </div>
          <div className="col-md-7 text-start font-medium text-uppercase">
            <h1 className='text-black fs-3'>Our Team of Multidisciplinary Designers, Creatives, and Artists will breathe life into your idea</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselVideoExample" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselVideoExample" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div> */}
<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src='../Images/Branding/Branding-Nesod-2.webp' className="d-block w-full" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="..." className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="..." className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>


       
    <div className="col-12 flex justify-center mb-10"> {/* Added margin for spacing */}
      <div className="relative top-16"> {/* Adjusted positioning */}
        <div className="flex justify-center">
          <img src={img2} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">BRANDING</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">The front of your business</span>
              <span className="block">from vision to Vector</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
              We create distinctive brand identities that reflect your business’s core values and connect with your target audience. Our branding services include logo design, typography, color schemes, and comprehensive style guides to ensure consistency across all platforms. Whether building a new brand or refreshing an existing one, we craft identities that resonate, foster trust, and make your brand memorable. Our strategic approach focuses on building recognition and loyalty through cohesive visual storytelling, ensuring your brand stands out in a competitive market and leaves a lasting impression on customers.
            </p>
          </div>
        </div>

        <div>
        <div className="flex pt-10 justify-center">
          <img src={img3} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">PACKAGING</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">Elevate your product</span>
              <span className="block">With expertly crafted designs</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
            We design eye-catching, functional packaging that enhances your product’s appeal while maintaining practical considerations. Our packaging solutions are tailored to your brand’s identity, ensuring that your product stands out on the shelf and attracts customers. Whether for retail, food, or luxury products, we balance aesthetic beauty with usability, making sure your packaging is not only visually compelling but also sustainable and cost-effective. We focus on delivering designs that communicate your product’s value, making a memorable first impression and enhancing the overall customer experience from the moment they see your product.            </p>
          </div>
        </div>
        
      </div>

      <div>
        <div className="flex pt-10 justify-center">
          <img src={img4} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">PRESENTATIONS</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">Transform your ideas </span>
              <span className="block">into visually stunning presentations</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
            We design professional, visually impactful presentations that make your message clear and compelling. Whether for corporate meetings, sales pitches, or conferences, our custom-designed presentations are tailored to your brand’s style and goals. We focus on visual storytelling, combining engaging graphics, clean layouts, and clear messaging to create presentations that captivate and inform your audience. Our services ensure your presentations are not only informative but also visually striking, helping you communicate effectively, stand out, and leave a lasting impression on stakeholders or clients.         </p>
          </div>
        </div>
        
      </div>

      <div>
        <div className="flex pt-10 justify-center">
          <img src={img5} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">VISUAL MEDIA</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">Ignite your content</span>
              <span className="block">with engaging and captivating visuals</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
            Our visual media services create engaging and high-quality content, from videos to motion graphics, that communicates your brand’s message effectively. We handle the entire production process, from concept development to post-production, ensuring your content aligns with your brand and goals. Whether it’s promotional videos, product demos, or brand storytelling, our visual media services help you engage your audience across digital platforms. We focus on delivering compelling narratives through visual storytelling, boosting your brand’s visibility, and helping you build deeper connections with your target audience.  </p>
          </div>
        </div>
        
      </div>

      <div>
        <div className="flex pt-10 justify-center">
          <img src={img6} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">WEB</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">Unleash the power of your brand</span>
              <span className="block">with strategic UI & UX</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
            We design and develop custom websites that are visually stunning, responsive, and user-friendly. Our web solutions are tailored to your business needs, whether it’s an e-commerce platform, corporate site, or blog. We focus on user experience (UX), intuitive navigation, and seamless functionality to ensure maximum engagement and conversions. Our websites are optimized for SEO and mobile compatibility, delivering a consistent and attractive user experience across all devices. From concept to launch, we handle everything, including ongoing support and updates, ensuring your site remains secure, up-to-date, and aligned with your business goals.
            </p>          </div>
        </div>
        
      </div>

      <div>
        <div className="flex pt-10 justify-center">
          <img src={img7} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">ILLUSTRATION</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">Bring your ideas to life</span>
              <span className="block">with artistic vision</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
            Our illustration services add a creative and unique flair to your brand’s visual communication. Whether for marketing, editorial, or product design, we craft custom illustrations tailored to your brand’s voice and aesthetic. From simple, minimalist designs to detailed, complex visuals, our illustrations are crafted to captivate and enhance your message. They help explain complex ideas, tell stories, or simply add an artistic touch that sets your brand apart. Each illustration is created with your vision and goals in mind, ensuring it resonates with your audience and enhances your overall brand identity.
                        </p>          </div>
        </div>
        
      </div>

      <div className='pb-28'>
        <div className="flex pt-10  justify-center">
          <img src={img8} alt="Branding" />
        </div>
        <div>
          <div className="pl-6 pt-8"> {/* Adjust padding for small screens */}
            <h1 className="text-4xl bacl font-bold">3D</h1> {/* Adjusted text size */}
            <p className="mt-2">
              <span className="text-xl block">Another dimension of creativity</span>
              <span className="block">with immersive 3D designs</span>
            </p>
            <p className="mt-3 text-sm"> {/* Adjusted text size */}
            Our 3D design services bring concepts, products, and environments to life with exceptional realism. We specialize in 3D modeling, rendering, and animation, delivering detailed visuals for industries like real estate, product design, marketing, and entertainment. Whether you need product prototypes, architectural models, or engaging animations, our expertise ensures precision and creativity. We transform ideas into dynamic, interactive experiences that captivate and communicate effectively. Our 3D designs not only enhance visual appeal but also provide immersive experiences, helping your brand stand out and making complex ideas easier to grasp.                        </p>          </div>
        </div>
        
      </div>

      </div>
    </div>
    </div>
  );

 
  return (
    <>


 



      {/* Branding Section */}
      <div className=''>
      <div
        ref={divRef}
        className={`${
          isLargeScreen && isVisible ? 'transition-opacity duration-700 ease-in-out opacity-100 ' : ''
        } ${!isLargeScreen ? 'opacity-100' : 'opacity-0'}`}
      >

        <div className=" ">
          {/* Left column - Only visible on larger screens */}
          {isLargeScreen && (
            <>
    <div id="carouselVideoExample" data-mdb-carousel-init className="carousel slide carousel-fade" data-mdb-ride="carousel">
  {/* Indicators */}
  <div className="carousel-indicators">
    <button data-mdb-button-init type="button" data-mdb-target="#carouselVideoExample" data-mdb-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
    <button data-mdb-button-init type="button" data-mdb-target="#carouselVideoExample" data-mdb-slide-to={1} aria-label="Slide 2" />
    <button data-mdb-button-init type="button" data-mdb-target="#carouselVideoExample" data-mdb-slide-to={2} aria-label="Slide 3" />
  </div>

  {/* Inner */}
  <div className="carousel-inner  w-full h-screen">
    {/* First Slide */}
    <div className="carousel-item active">
      <video className="object-cover w-full h-screen" src={video} autoPlay muted loop />
      <div className="carousel-caption flex flex-col justify-center items-start h-screen ">
        <h1 className="text-4xl md:text-5xl lg:text-7xl text-black font-bold">
          <span className="ann">laconic.</span>
        </h1>
        <p className="asn text-xl text-black">
          adj. Expressing much in few words
        </p>
      </div>
    </div>

    {/* Second Slide */}
    <div className="carousel-item">
      <video className="object-cover w-full h-screen" src={video} autoPlay muted loop />
      <div className="carousel-caption flex flex-col justify-center items-start  h-screen ">
        <div className="flex flex-col lg:flex-row  w-full ">
          {/* First Column */}
          <h1 className="lg:w-1/3 text-4xl p-0 m-0 md:text-5xl lg:text-5xl text-black font-bold text-right">
            <p className=''>We <br /> are <br /> Artists</p>
          </h1>

          <div className="hidden lg:block bg-black text-black mx-2">n</div>

          {/* Second Column */}
          <div className="lg:w-1/2 text-xl md:text-3xl mt-2 text-black lg:text-left font-medium">
            <h1>Our values: Artistic Exploration <br /> Clear Communication with<br /> Creative Input & Feedback</h1>
          </div>
        </div>
      </div>
    </div>

    {/* Third Slide */}
    <div className="carousel-item">
      <video className="object-cover w-full h-screen" src={video} autoPlay muted loop />
      <div className="carousel-caption flex flex-col justify-center items-start h-screen">
        <div className="flex flex-col lg:flex-row justify-center w-full ">
          {/* First Column */}
          <div className="lg:w-1/3 text-4xl md:text-5xl lg:text-6xl text-black font-bold text-right">
            <p>Explore <br /> Design <br /> Create</p>
          </div>

          <div className="hidden lg:block  mt-4 mx-2">
            {/* Replace with your icons */}
            <img src={tri2} className="h-5 mb-8" />
            <img src={tri} className="h-5 mb-8" />
            <img src={tri1} className="h-7" />
          </div>

          {/* Second Column */}
          <div className="lg:w-1/2 text-xl md:text-3xl text-black mt-4 lg:text-left font-medium">
            <h1>Our team of Multidisciplinary <br /> Designers, Creatives, and Artists <br /> Will Breathe Life into Your Idea</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Controls */}
  <button data-mdb-button-init className="carousel-control-prev" type="button" data-mdb-target="#carouselVideoExample" data-mdb-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button data-mdb-button-init className="carousel-control-next" type="button" data-mdb-target="#carouselVideoExample" data-mdb-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

     


              <div className="col-12 col-md-12 col-lg-12 p-40 pb-10">
                <MultiImage />
              
              </div>

            </>
          )}

          {/* Small Screen Content */}
          {!isLargeScreen && (
            <>
           


            <div className="d-block d-md-none mt-10">
              {/* Display content 3 times */}
              <BrandingSection />
            
            </div>
            </>
          )}
        </div>
      </div>
      </div>
    </>
  );
};

export default Landing;

            