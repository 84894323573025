import './index.css';
import Navbar from "./Components/Navbar";
import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './Pages/Landing';
import video from './Images/bg.webm';

import Footer from './Components/Footer';
import Services from './Pages/Services';
import Packaging from './Pages/Packaging';
import Presentation from './Pages/Presentation';
import Illustration from './Pages/Illustration';
import Web from './Pages/Web';
import ThreeD from './Pages/ThreeD';
import About from './Pages/About';
import Visual from './Pages/Visual';


function App() {
  const [loading, setLoading] = useState(true);

  // Timer to transition after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Router>
        {loading ? <Loader loading={loading} /> : <Navbar />}
        <Routes>
          <Route exact path="/" element={loading ? <Loader loading={loading} /> : <Landing />} />
          <Route exact path="/branding" element={loading ? <Loader loading={loading} /> : <Services />} />{/* branding */}
          <Route exact path="/packaging" element={loading ? <Loader loading={loading} /> : <Packaging />} />
          <Route exact path="/presentation" element={loading ? <Loader loading={loading} /> : <Presentation />} />
          <Route exact path="/illustration" element={loading ? <Loader loading={loading} /> : <Illustration />} />
          <Route exact path="/web" element={loading ? <Loader loading={loading} /> : <Web />} />
          <Route exact path="/visual" element={loading ? <Loader loading={loading} /> : <Visual />} />
          <Route exact path="/3d" element={loading ? <Loader loading={loading} /> : <ThreeD />} />
          <Route exact path="/about" element={loading ? <Loader loading={loading} /> : <About />} />

        </Routes>
        {loading ? <Loader loading={loading} /> : <Footer />}
      </Router>
    </div>
  );
}

const Loader = ({ loading }) => {
  // Use react-spring for the slide effect
  const loaderSpring = useSpring({
    transform: loading ? 'translateY(0%)' : 'translateY(-100%)', // Slide down and slide back up
    from: { transform: 'translateY(-100%)' }, // Starts from out of the view
    config: { duration: 1000 }, // Control the speed of the animation
  });

  return (
    <animated.div style={{ ...loaderSpring, backgroundColor: 'black', zIndex:'1000', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', width: '100%' }}>
     {/*    <div className="carousel-caption absolute top-36 d-none d-md-block">
        <h1 className='text-7xl  text-black '>
         <span className='font-bold bacl absolute lg:top-0 lg:left-10 nnnn'> laconic.</span>
        </h1>
        <p className='text-3xl absolute bacl left-16 lg:top-64 text-black '>adj. Expressing much in few words</p>
      
      </div> */}
    <div className="contains">  
    <video className="" src={video} autoPlay muted loop playsInline />
  
  <div className="text-box">
  <h1 className='text-7xl '>
         <span className='font-bold nnnn'> laconic.</span>
        </h1>
        
      
  </div>

  

</div>

    </animated.div>
  );
};

export default App;
