import React from 'react'
import video from '../Images/bg.webm'; 
import { IoStar } from "react-icons/io5";
const About = () => {
  return (
    <div className='llllll'>
      <div className="container video-container ">
        <div className='container hhh pt-10 '>
        <video className="bg-video2 rounded-xl rounded-tl-3xl rounded-b-3xl" src={video} autoPlay muted loop playsInline />
         </div>
        <div className=" inset-0 flex justify-center items-center ">
          <div className="nj flex flex-col bg-black rounded-tl-xl rounded-br-3xl justify-center items-start">
            <h1 className="text-9xl  text-white font-extrabold uuuu  ">ABOUT US</h1>
          </div>
          <div className=" hhhd  p-3  rounded-bl-3xl space-y-3 font-thin text-wrap flex flex-col bg-black  justify-start items-start">
           <h1 className='bb'>Our agency embodies simplicity and sophistication. </h1>
           <p>With a passion for visual storytelling,
we transform ideas into impactful designs<br/>
that resonate with clarity and purpose.At laconic, we believe in the power of minimalism<br/>
to convey maximum impact, creating a visual language that speaks directly to the heart of your message.<br/>

Join us on a journey where every pixel counts,<br/>
and design is more than just an art,<br/>
it's a powerful form of communication.</p>
          </div>
          <div className="nnbn flex flex-col bg-black rounded-tl-xl rounded-br-2xl p-3  justify-center items-start">
            <h1 className="text-4xl  text-white font-thin ">Expressing much in few words</h1>
          </div>



          
        </div>
      
       
      </div>
      <div className='container'>

      <div className="mt-10 max-w-full mx-auto space-y-10"> {/* Main Container */}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"> {/* Grid layout for 4 items */}
        {[1, 2, 3, 4].map((item, index) => (  // Simulating 4 elements
          <div key={index} className="w-full flex flex-col items-center space-y-4"> {/* Ensure content is vertically centered */}
            
            <div className="flex space-x-2 justify-center">
              {[...Array(5)].map((_, idx) => ( // Loop to generate 5 stars
                <div key={idx} className="relative w-12 h-12">
                  <IoStar size={60} className="absolute inset-0 text-transparent" />
                  <div className="absolute inset-0 clip-star">
                    <video
                      className="h-full w-full object-cover"
                      src={video}
                      autoPlay
                      muted
                      loop
                      playsInline
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* Review content with flexible height */}
            <div className="text-left space-y-2"> 
              <h1 className="font-semibold text-lg">Nailed it. Outstanding result, worth the investment. I never thought I would go in this direction, but his approach was awesome!</h1>
              <h1 className="">Maksims Z.</h1>
            </div>
          </div>
        ))}
      </div>

    </div>
      </div>

    </div>
  )
}

export default About
